import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default function useAccountOffers() {
  const user = useUserStore();

  const { offersNannyReady } = storeToRefs(user);

  const initAccountOffers = async () => {
    try {
      await user.refetchUserStore();
    } catch (error) {
      console.log(error);
    }
  };

  onMounted(() => {
    if (user.storeMeta.isInitialized === true) {
      console.log("INIT MY_OFFERS");
      initAccountOffers();
    }
  });

  const router = useRouter();

  const createOffer = () => {
    router.push({ name: "OfferNannyCreate" });
  };

  const updateOffer = (id) => {
    router.push({ name: "OfferNannyUpdate", params: { id: id } });
  };

  const refreshOffer = async (id) => {
    try {
      await user.refreshOffer({ id: id, offerClass: "nanny" });
      console.log("OFFER REFRESHED");
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const archiveOffer = async (id) => {
    try {
      await user.archiveOffer({ id: id, offerClass: "nanny" });
      console.log("OFFER ARCHIEVED");
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const runOffer = async (id) => {
    try {
      await user.runOffer({ id: id, offerClass: "nanny" });
      console.log("OFFER PUBLISHED");
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const pauseOffer = async (id) => {
    try {
      await user.pauseOffer({ id: id, offerClass: "nanny" });
      console.log("OFFER PAUSED");
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return {
    offersNannyReady,
    createOffer,
    updateOffer,
    archiveOffer,
    pauseOffer,
    refreshOffer,
    runOffer,
  };
}
