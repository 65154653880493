<template>
  <svg
    width="15"
    height="22"
    viewBox="0 0 15 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7795 11C10.5642 15.5208 3.82037 15.5208 0.605116 11C3.82037 6.47919 10.5642 6.47919 13.7795 11Z"
      fill="#6B759E"
      stroke="#6A799C"
    />
    <path
      d="M9.40705 11.0707C9.40705 12.2532 8.44848 13.2117 7.26603 13.2117C6.08357 13.2117 5.125 12.2532 5.125 11.0707C5.125 9.88826 6.08357 8.92969 7.26603 8.92969C8.44848 8.92969 9.40705 9.88826 9.40705 11.0707Z"
      fill="#6A799C"
      stroke="#E8F4FF"
      stroke-width="1.5"
    />
  </svg>
</template>
