<template>
  <div class="content-wrapper">
    <div v-if="offersNannyReady.value.length > 0" class="content">
      <BaseH1 :text="'Moje ogłoszenia'" :short="true" />

      <div class="my-offers content__my-offers">
        <div
          v-for="offer in offersNannyReady.value"
          :key="offer.id"
          class="my-offer my-offers__my-offer"
        >
          <OfferNannyListItem
            :item="offer"
            :is-geo="false"
            :is-views-amount="true"
          />
          <div class="buttons my-offer__buttons">
            <ButtonPrimary
              v-if="offer.meta.status === 'archived'"
              @click="runOffer(offer.id)"
              :label="'Aktywuj'"
              :size="'small'"
            />
            <ButtonBasic
              v-if="offer.meta.status === 'published'"
              @click="refreshOffer(offer.id)"
              :label="'Odśwież'"
              :size="'small'"
            />
            <ButtonTertiary
              v-if="offer.meta.status === 'published'"
              @click="updateOffer(offer.id)"
              :label="'Edytuj'"
              :size="'small'"
            />
            <ButtonLink
              v-if="offer.meta.status === 'published'"
              @click="archiveOffer(offer.id)"
              :label="'Ukryj'"
              class="buttons__link"
            />
          </div>
        </div>
      </div>
    </div>

    <AccountOffersEmpty v-else />

    <NavMobileBack v-if="isMobile" />
  </div>
</template>

<script>
import useAccountOffers from "@/composables/useAccountOffers";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import OfferNannyListItem from "@/components/Offer/Nanny/OfferNannyListItem.vue";
import ButtonBasic from "@/components/UI/Base/ButtonBasic.vue";
import ButtonTertiary from "@/components/UI/Base/ButtonTertiary.vue";
import NavMobileBack from "@/components/NavMobileBack.vue";
import useIsMobile from "@/composables/useIsMobile";
import AccountOffersEmpty from "@/views/Account/AccountOffersEmpty.vue";
import ButtonPrimary from "@/components/UI/Base/ButtonPrimary.vue";

export default {
  components: {
    BaseH1,
    OfferNannyListItem,
    ButtonBasic,
    ButtonTertiary,
    NavMobileBack,
    AccountOffersEmpty,
    ButtonPrimary,
  },

  setup() {
    const { isMobile } = useIsMobile();

    const {
      offersNannyReady,
      createOffer,
      updateOffer,
      archiveOffer,
      refreshOffer,
      runOffer,
    } = useAccountOffers();

    return {
      isMobile,
      offersNannyReady,
      updateOffer,
      refreshOffer,
      archiveOffer,
      createOffer,
      runOffer,
    };
  },
};
</script>

<style scoped>
.content-wrapper {
  width: 100%;
}
.content {
  width: 100%;
  padding: 60px 0;
  display: flex;
  flex-flow: column;
  row-gap: 60px;
}
.content__my-offers {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
.my-offers__my-offer {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.my-offer__buttons {
  margin-top: 30px;
  margin-right: 30px;
  width: fit-content;
  display: flex;
  flex-flow: row;
  column-gap: 30px;
}

@media (max-width: 1200px) {
  .content {
    padding: 30px 0;
    display: flex;
    flex-flow: column;
    row-gap: 30px;
  }
  .content__my-offers {
    display: flex;
    flex-flow: column;
    row-gap: 60px;
  }
  .my-offers__my-offer {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
  .my-offer__buttons {
    margin: 30px 0 0;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    row-gap: 20px;
    column-gap: 11px;
  }
  .buttons__link {
    width: var(--button-width);
  }
}
</style>
